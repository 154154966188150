import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./app.css";

import Header from "./shared/HeaderComponent/header.component";
import Nav from "./shared/NavComponent/nav.component";
import Profile from "./screens/ProfileComponent/profile.component";
import Stats from "./screens/StatsComponent/stats.component";
import Community from "./screens/CommunityComponent/community.component";
import FooterMenu from "./shared/FooterMenuComponent/footerMenu.component";
import UserOpinionComponent from "./shared/UserOpinionComponent/userOpinion.component";
import UnauthorizedPage from "./screens/ErrorPagesComponents/unauthorizedPage.component";
import Page401 from "./screens/ErrorPagesComponents/401Page.component";
import WattingPage403 from "./screens/ErrorPagesComponents/403Page.component";
import Page500 from "./screens/ErrorPagesComponents/500Page.component";
import { ResolutionContextProvider } from "./Utils/screen_resolution.hook";


function App() {
  // MacAddress de la box de Gregory Lamotte pi2-6b-80-4a
  const url = window.location.href;
  let macAddress = sessionStorage.getItem("macAddress") ? sessionStorage.getItem("macAddress") : url.split("/").pop();

  if (url.includes("local")) {
    sessionStorage.setItem("macAddress", macAddress);
  } else if (url.includes("preprod") || url.includes("go") || url.includes("energy")) {
    sessionStorage.setItem("macAddress", macAddress);
  }

  return (
    <div>
      <ResolutionContextProvider>
        <Header />
        <Nav />
        <Router>
          <div className="mainContent">
            <Switch>
              <Route path="/Unauthenticated">
                <Page401 />
              </Route>
              <Route path="/403">
                <WattingPage403 />
              </Route>
              <Route path="/FormIncomplete">
                <UnauthorizedPage />
              </Route>
              <Route path="/ErrorServer">
                <Page500 />
              </Route>
              <Route path={"/Profile/" + macAddress}>
                <Profile />
              </Route>
              <Route path={"/Stats"}>
                <Stats />
              </Route>
              <Route path={"/Community"}>
                <Community />
              </Route>
              <Route path="/">
                <Redirect to={"/Profile/" + macAddress} />
              </Route>
            </Switch>

            <UserOpinionComponent />
          </div>
          <FooterMenu />
        </Router>

        <ToastContainer />
      </ResolutionContextProvider>
    </div>
  );
}

export default App;
