import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./footerMenu.component.css";
import { MenuContainer, MenuUl, MenuLi } from "./footerMenuStyled";

function FooterMenu() {
  const location = useLocation();
  const macAddress = sessionStorage.getItem("macAddress");

  return (
    <MenuContainer>
      <MenuUl>
        <MenuLi selected={location.pathname.includes("/Profile") || location.pathname === "/"}>
          <Link to={"/Profile/" + macAddress + "/?redirectionToStats=false"}>
            <div className="icon icon-maison footerMenuIcon"></div>
            Profil
          </Link>
        </MenuLi>
        <MenuLi selected={location.pathname.includes("/Stats")}>
          <Link to={"/Stats"}>
            <div className="icon icon-graph-area footerMenuIcon"></div>
            Stats
          </Link>
        </MenuLi>
        <MenuLi selected={location.pathname.includes("/Community")}>
          <Link to={"/Community"}>
            <div className="footerMenuIcon icon-users"></div>
            Communauté
          </Link>
        </MenuLi>
      </MenuUl>
    </MenuContainer>
  );
}

export default FooterMenu;
